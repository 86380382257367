import { logger } from 'Services/logger/logger';

const captureExceptionWithTeam = (error, teamName, tags, extra) => {
	logger.warn({
		context: {
			extra: extra ? { ...extra } : undefined,
			tags: { ...tags, team: teamName },
		},
		error: new Error(error),
		message: error?.message || 'There was an error',
		scope: 'captureExceptionWithTeam',
	});
};

export default captureExceptionWithTeam;
