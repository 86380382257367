import { InfoIcon, TickCircleIcon, WarningIcon } from '@motorway/mw-highway-code';

import type { SubDesc, SubDescType } from './OTPContent.types';

const getIconForSubDesc = (type?: SubDescType) => {
	if (type === 'error') {
		return WarningIcon;
	}
	if (type === 'success') {
		return TickCircleIcon;
	}
	return InfoIcon;
};

export const getSubDescState = (text: string, type?: SubDescType): SubDesc => ({
	icon: getIconForSubDesc(type),
	id: 'payment-otp-sub-desc',
	text,
	type,
});
