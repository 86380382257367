import React from 'react';

import { baseGAEvent } from 'Services/gtm';

export const footerLinkGaEventHandler = (label: React.ReactNode): void =>
	baseGAEvent({
		action: 'Footer link clicked',
		category: 'Sitewide footer',
		label: label?.toString() || '',
	});
