import { useCallback, useEffect, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { MDText } from 'i18n-react';
import { useRouter } from 'next/router';

import { Hyperlink } from '@motorway/mw-highway-code/alpha';
import { useConstantCallback } from '@motorway/mw-highway-code/hooks';

import { withdrawFundsUpdateError } from 'Components/pages/WithdrawFunds/WithdrawFundsEmitter/WithdrawFundsEmitter.events';
import { generateOTP } from 'Services/api/payment';
import captureExceptionWithTeam from 'Utilities/captureException/captureExceptionWithTeam';
import { TEAM } from 'Utilities/consts';

import { PHONES } from '../../VehiclePayment.helpers';

import Texts from './OTPContent.texts';
import type { UseOTPProps, UseOTPReturn } from './useOTP.helper';
import { getOTPLogParams, getReadableFormat, TIME_BETWEEN_SMS } from './useOTP.helper';

const LocalT = new MDText(Texts);

const updateWithdrawalsError = (message: string) => {
	// in future, we can change it to general error emitter
	if (message.endsWith?.('otp.rate_limit_reached')) {
		withdrawFundsUpdateError({
			key: 'otp',
			message: LocalT.translate('error.rate_limit_reached.message'),
			title: LocalT.translate('error.rate_limit_reached.title'),
		});
	} else {
		withdrawFundsUpdateError({
			key: 'otp',
			message: LocalT.translate('error.general.message', {
				phone: <Hyperlink href={`tel:${PHONES.GENERAL_PAYMENTS_ERROR}`} label={PHONES.GENERAL_PAYMENTS_ERROR} />,
			}),
			title: LocalT.translate('error.general.title'),
		});
	}
};

export const useOTP = ({ disabled, enquiryId, source }: UseOTPProps): UseOTPReturn => {
	const [requestInProgress, setRequestInProgress] = useState<boolean>(!disabled);
	const [sendCodeTime, setSendCodeTime] = useState<number>(0);
	const [countDown, setCountDown] = useState<number>(TIME_BETWEEN_SMS);

	const router = useRouter();

	const interval = useRef<number | null>(null);
	const mounted = useRef<boolean>(false);

	const stopCounter = useCallback(() => {
		if (interval.current) {
			window.clearInterval(interval.current);
		}

		if (mounted.current) {
			setCountDown(0);
		}
	}, []);

	const startCounter = useCallback(() => {
		if (interval.current) {
			window.clearInterval(interval.current);
		}

		interval.current = window.setInterval(() => {
			if (mounted.current) {
				const newCountDown = Math.max(sendCodeTime + TIME_BETWEEN_SMS - new Date().getTime(), 0);
				setCountDown(newCountDown);
				if (newCountDown === 0) {
					stopCounter();
				}
			}
		}, 1000);
	}, [sendCodeTime, stopCounter]);

	const getCode = useConstantCallback(() => {
		if (disabled) return;

		setRequestInProgress(true);
		setCountDown(TIME_BETWEEN_SMS);

		generateOTP({
			enquiryId,
			logs: getOTPLogParams(router),
			source,
		})
			.then(() => {
				setRequestInProgress(false);
				setSendCodeTime(new Date().getTime());
			})
			.catch((err) => {
				const { message = '' } = ((err as AxiosError)?.response?.data as { message: string }) || {};

				// in future, we can change it to general error emitter
				updateWithdrawalsError(message);

				captureExceptionWithTeam(err, TEAM.PAYMENTS_FE, undefined, { stringifyError: JSON.stringify(err) });
			});
	});

	useEffect(() => {
		if (sendCodeTime) {
			startCounter();
		}
	}, [sendCodeTime, startCounter]);

	useEffect(() => {
		mounted.current = true;
		getCode();
		return () => {
			mounted.current = false;
			stopCounter();
		};
	}, [getCode, stopCounter]);

	return {
		countDown,
		getCode,
		readableCountDown: getReadableFormat(countDown),
		requestInProgress,
		resendButtonAvailable: !requestInProgress && countDown < 1000,
		stopCounter,
	};
};
