import React, { ReactElement } from 'react';
import { NavigationMenuTrigger, NavigationMenuTriggerProps } from '@radix-ui/react-navigation-menu';

export type TriggerProps = NavigationMenuTriggerProps;

const Trigger = ({ children, ...props }: TriggerProps): ReactElement => (
	<NavigationMenuTrigger asChild {...props}>
		{children}
	</NavigationMenuTrigger>
);

export default Trigger;
