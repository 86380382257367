import React from 'react';
import ReactDOM from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';
import cx from 'classnames';
import { MDText } from 'i18n-react';
import { useRouter } from 'next/router';

import { Button, CrossIcon } from '@motorway/mw-highway-code';

import OTPContent from 'Components/pages/VehiclePayment/components/OTPContent/OTPContent';
import { OTPContentProps } from 'Components/pages/VehiclePayment/components/OTPContent/OTPContent.types';
import { onRequestCatch } from 'Components/pages/VehiclePayment/components/OTPContent/OTPRequestCatch.helper';
import { getOTPLogParams } from 'Components/pages/VehiclePayment/components/OTPContent/useOTP.helper';
import Texts from 'Components/texts.json';
import { verifyOTP } from 'Services/api/payment';
import Content from 'Storybook/local/Content/Content';
import useUser from 'Utilities/hooks/useUser';

import styles from './MFAWrapper.module.scss';

const LocalT = new MDText(Texts.MFA);

interface MFAWrapperProps {
	fullScreen?: boolean;
	logs?: OTPContentProps['logs'];
	onBackClick?: () => void;
	onSuccess: () => void;
	portalId?: string;
}

const MFAWrapper = ({
	fullScreen,
	logs = { source: 'accessSensitiveData' },
	onBackClick,
	onSuccess,
	portalId,
}: MFAWrapperProps): JSX.Element => {
	const { otpPhone } = useUser();
	const router = useRouter();
	const endOfPhone = otpPhone?.slice?.(otpPhone.length - 3, otpPhone.length) || '';

	const logInWithMFA: OTPContentProps['request'] = async (verificationCode) => {
		try {
			await verifyOTP({
				logs: getOTPLogParams(router),
				source: 'accessSensitiveData',
				verificationCode,
			});

			return () => {
				onSuccess();
			};
		} catch (error) {
			throw await onRequestCatch({ error });
		}
	};

	const Wrapper = fullScreen ? RemoveScroll : React.Fragment;

	const result = (
		<Wrapper>
			<div className={cx(styles.MFAWrapper, { [styles.fullScreen]: fullScreen })} data-testid="MFA-wrapper">
				<Content className={styles.container}>
					{fullScreen && onBackClick && (
						<div className={styles.toolbar}>
							<Button
								fullWidth
								icon={CrossIcon}
								label="close"
								onClick={onBackClick}
								showLabel={false}
								variant="tertiary"
							/>
						</div>
					)}

					<div className={styles.content}>
						<OTPContent
							desc={`${LocalT.translate('desc', { number: endOfPhone })}`}
							extraStyles={{
								paymentsOTPContent: styles.paymentsOTPContent,
								title: styles.title,
							}}
							logs={logs}
							request={logInWithMFA}
							title={`${LocalT.translate('title')}`}
						/>
					</div>
				</Content>
			</div>
		</Wrapper>
	);

	if (portalId) {
		const container = document.getElementById(portalId);

		if (container) {
			return ReactDOM.createPortal(result, container);
		}
	}

	return result;
};

export default MFAWrapper;
