import isObject from 'lodash/isObject';

import { WizardContext } from 'Components/shared/Wizard/Wizard.types';
import { ApiErrorResponseForJsend } from 'Services/motorway/apiClient.types';
import captureExceptionWithTeam from 'Utilities/captureException/captureExceptionWithTeam';
import { TEAM } from 'Utilities/consts';
import { isAxiosError } from 'Utilities/isAxiosError';

type OnRequestCatchResponse = ApiErrorResponseForJsend | undefined;

type OnRequestCatch = (props: {
	callback?: (data?: OnRequestCatchResponse) => Promise<void>;
	error: unknown;
	onWizardSubmit?: WizardContext['onWizardSubmit'];
}) => Promise<OnRequestCatchResponse>;

const handleError = (err: unknown) => {
	const errMessage = isObject(err) && 'message' in err ? `${err.message}` : 'onRequestCatch Error';

	let errRes: ApiErrorResponseForJsend = {
		message: errMessage,
		status: 'error',
	};

	if (isAxiosError<ApiErrorResponseForJsend>(err)) {
		if (err.response?.data) {
			errRes = err.response.data;
		}
	}

	return errRes;
};

export const OTP_RELATED_ERRORS = new Set([
	'verification_code_required',
	'verification_code_failed',
	'rate_limit_reached',
	'verification_limit_reached',
]);

export const onRequestCatch: OnRequestCatch = async ({ callback, error, onWizardSubmit }) => {
	const { message } = handleError(error);

	captureExceptionWithTeam(`OTPRequestCatch - ${message}`, TEAM.PAYMENTS_FE);

	if (message.endsWith('verification_code_required') || message.endsWith('verification_code_failed')) {
		if (callback) {
			await callback();
		}

		return undefined;
	}

	if (message.endsWith('rate_limit_reached')) {
		const data: OnRequestCatchResponse = { message: 'rate_limit_reached', status: 'error' };

		if (callback) {
			await callback(data);
		}

		return data;
	}

	if (message.endsWith('verification_limit_reached')) {
		const data: OnRequestCatchResponse = { message: 'verification_limit_reached', status: 'error' };

		if (callback) {
			await callback(data);
		}

		return data;
	}

	if (callback) {
		await callback();
	}

	if (typeof onWizardSubmit === 'function') {
		if (message.endsWith('insufficient_funds')) {
			window.location.reload();
			return undefined;
		}

		await onWizardSubmit({ hasError: true, journey: 'error' });

		return undefined;
	}

	return undefined;
};
