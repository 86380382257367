import { ReactElement } from 'react';

import { Badge, BadgeProps } from '@motorway/mw-highway-code';

type Version = 'Alpha' | 'Beta';

type LowerCaseVersion = Lowercase<Version>;

type UpperCaseVersion = Uppercase<Version>;

export type MixedCaseVersion = Version | LowerCaseVersion | UpperCaseVersion;

export type VersionBadgeProps = {
	text?: MixedCaseVersion;
	variant?: BadgeProps['variant'];
	wrapperClassName?: string;
};

const VersionBadge = ({ text = 'Alpha', variant = 'default', wrapperClassName }: VersionBadgeProps): ReactElement => (
	<div className={wrapperClassName}>
		<Badge text={text} variant={variant} />
	</div>
);

export default VersionBadge;
