import React from 'react';
import { NextRouter } from 'next/router';

import { useBilling } from 'Components/Billing/useBilling';
import MFAWrapper from 'Components/MFAWrapper/MFAWrapper';
import { defaultWalletUrl } from 'Components/pages/Wallet/Wallet.const';
import { applySessionReplayMask } from 'Services/datadog/datadogRum';
import { MASKED_EVENT_VALUES } from 'Services/datadog/datadogRum.const';
import Separator from 'Storybook/core/Separator/Separator';
import NavAccordion from 'Storybook/local/NavAccordion/NavAccordion';
import {
	getLoggedInNavAccordionItems,
	getOtherNavAccordionItems,
	getProductNavAccordionItems,
} from 'Storybook/local/NavAccordion/NavAccordion.helpers';
import { NavAccordionItem } from 'Storybook/local/NavAccordion/NavAccordion.types';
import MenuButton from 'Storybook/peripheral/MenuButton/MenuButton';
import { User } from 'Types/user';
import { PAYMENT_MFA_ELEMENT_ID } from 'Utilities/consts';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';
import usePaymentsMVPEnabled from 'Utilities/hooks/usePaymentsMVPEnabled';
import usePaymentsPermission from 'Utilities/hooks/usePaymentsPermission';
import useTradePermission from 'Utilities/hooks/useTradePermission';
import useVehicleListButtonConfig from 'Utilities/hooks/vehicleList/useVehicleListButtonConfig';
import ROUTES from 'Utilities/routes';

import { triggerUserSiteNavigationGAEvent } from '../../SiteNavigation.analytics';
import { useSignOut } from '../../utilities/hooks/useSignOut';

import { UserMenuHeader } from './UserMenuLinks/UserMenuHeader';
import { getMobileNavFooterMenu } from './UserMenu.helpers';

import styles from './UserMenu.module.scss';

const { dealer, user: ddUserValues } = MASKED_EVENT_VALUES;
const privacyParams = {
	dealershipName: applySessionReplayMask({ maskedActionName: dealer.name }),
	usersName: applySessionReplayMask({ maskedActionName: ddUserValues.name }),
};

export type UserMenuProps = {
	router: NextRouter;
	user: User;
};

const UserMenu: React.FC<UserMenuProps> = ({ router, user }) => {
	const { isBillingEnabled, onBillingClick, onSuccess, portalVisible, setPortalVisible } = useBilling();
	const isPaymentMVPEnable = usePaymentsMVPEnabled();
	const hasTradePermission = useTradePermission();
	const hasPaymentsPermission = usePaymentsPermission();
	const { onSignOut } = useSignOut();
	const { pathname, push } = router;
	const isAccountSettingsActive = pathname.includes(ROUTES.ACCOUNT_SETTINGS.href);

	const onWalletClick = () => push(defaultWalletUrl);

	const isTransportExperience = useFeatureToggle(FEATURES.transportExperience);
	const isTradeExperience = useFeatureToggle(FEATURES.tradeExperience);
	const isTradeNavigationEnabled = useFeatureToggle(FEATURES.tradeNavigation);

	const FOOTER_BUTTONS = getMobileNavFooterMenu({ onSignOut });

	const NAV_ACCORDION_LISTS: NavAccordionItem[][] = [];

	const marketplaceButton = useVehicleListButtonConfig();
	const NAV_ACCORDION_ITEMS = getLoggedInNavAccordionItems({
		marketplaceItem: { as: marketplaceButton.as, href: marketplaceButton.href },
	});
	NAV_ACCORDION_LISTS.push(NAV_ACCORDION_ITEMS);

	const MISC_ACCORDION_ITEMS = getOtherNavAccordionItems({
		isBillingEnabled,
		isWalletEnabled: isPaymentMVPEnable && hasPaymentsPermission,
		onBillingClick,
		onWalletClick,
		showTradeNavigationItem: isTradeNavigationEnabled && hasTradePermission,
	});
	NAV_ACCORDION_LISTS.push(MISC_ACCORDION_ITEMS);

	const PRODUCT_ACCORDION_ITEMS = getProductNavAccordionItems({
		isTradeExperience,
		isTransportExperience,
		triggerGAEvent: triggerUserSiteNavigationGAEvent,
	});
	NAV_ACCORDION_LISTS.push(PRODUCT_ACCORDION_ITEMS);

	return (
		<div className={styles.container}>
			<UserMenuHeader
				dealerName={user.dealerName}
				isAccountSettingsActive={isAccountSettingsActive}
				name={user.name}
				privacyParams={privacyParams}
			/>
			<Separator width="large" />
			<div className={styles.navContainer}>
				<NavAccordion lists={NAV_ACCORDION_LISTS} router={router} />
			</div>

			{portalVisible && (
				<MFAWrapper
					fullScreen
					onBackClick={() => {
						setPortalVisible(false);
					}}
					onSuccess={onSuccess}
					portalId={PAYMENT_MFA_ELEMENT_ID}
				/>
			)}

			<Separator width="large" />
			<div className={styles.footer}>
				{FOOTER_BUTTONS.map(({ as, cypressId, href, icon: Icon, id, onClick, text }) => (
					<MenuButton
						key={id}
						as={as}
						cypressId={cypressId}
						href={href}
						icon={Icon}
						id={id}
						onClick={onClick}
						text={text}
					/>
				))}
			</div>
		</div>
	);
};

export default UserMenu;
