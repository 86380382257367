import React, { useRef, useState } from 'react';
import cx from 'classnames';
import { Button } from '@THC/components/Button/Button';
import { MDText } from 'i18n-react';

import { IconText, InputAuth } from '@motorway/mw-highway-code';
import { Hyperlink } from '@motorway/mw-highway-code/alpha';

import cypressIds from 'Components/cypress_ids.json';
import { SNOWPLOW_BACKEND_SERVICES } from 'Services/analytics/analytics.const';
import { otpEventsBackendTrigger } from 'Services/analytics/events/paymentEvents/otpEvents';
import { applySessionReplayMask } from 'Services/datadog/datadogRum';
import { MASKED_EVENT_VALUES } from 'Services/datadog/datadogRum.const';
import { applyCypressData } from 'Utilities/helpers';
import useUser from 'Utilities/hooks/useUser';

import { GA_OTP_LABEL, OTP_ACTION } from '../../VehiclePayment.const';
import { PHONES } from '../../VehiclePayment.helpers';
import Texts from '../../VehiclePayment.texts.json';

import { getSubDescState } from './OTPContent.helper';
import type { OTPContentProps, SubDesc } from './OTPContent.types';
import { useOTP } from './useOTP';
import { OTPSource } from './useOTP.helper';

import styles from './OTPContent.module.scss';

const LocalT = new MDText(Texts.OTPModal);

const { OTP } = MASKED_EVENT_VALUES;
const privacyParams = { description: applySessionReplayMask({ maskedActionName: OTP.description }) };

const OTPContent = ({
	ctaNoNumber = `${LocalT.translate('CTANoNumber')}`,
	ctaNoNumberSupport = LocalT.translate('CTANoNumberSupport', {
		number: <Hyperlink href={`tel:${PHONES.PAYMENT_ERROR.replace(/ /g, '')}`} label={PHONES.PAYMENT_ERROR} />,
	}),
	ctaResend = `${LocalT.translate('CTAResend')}`,
	ctaWait = `${LocalT.translate('CTAWait')}`,
	desc,
	enquiryId = undefined,
	extraStyles = { paymentsOTPContent: undefined, title: undefined },
	logs,
	request,
	title = `${LocalT.translate('title')}`,
}: OTPContentProps): React.ReactElement => {
	enquiryId = (['createPostSaleOffer', 'approvePostSaleOffer'] as OTPSource[]).includes(logs.source)
		? enquiryId
		: undefined;

	const { otpPhone, phoneConfirmedAt } = useUser();
	const isOTPDisabled = !otpPhone || phoneConfirmedAt === null;

	const [subDesc, setSubDesc] = useState<SubDesc | undefined>(undefined);

	const { getCode, readableCountDown, resendButtonAvailable } = useOTP({
		disabled: isOTPDisabled,
		enquiryId,
		source: logs.source,
	});

	const errorAttempt = useRef<number>(0);

	let defaultDesc;

	if (!desc && otpPhone) {
		const endOfPhoneNumber = otpPhone.slice(otpPhone.length - 3, otpPhone.length);
		defaultDesc = `${LocalT.translate('desc', { number: endOfPhoneNumber })}`;
	}

	if (isOTPDisabled) {
		return (
			<div className={cx(styles.paymentsOTPContent, extraStyles.paymentsOTPContent)}>
				<div>
					<h1 className={cx(styles.title, extraStyles.title)}>{title}</h1>
					<div className={styles.desc}>
						<p>{ctaNoNumber}</p>
						<p>{ctaNoNumberSupport}</p>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div
			{...applyCypressData(cypressIds.payments.otp.otpModal)}
			className={cx(styles.paymentsOTPContent, extraStyles.paymentsOTPContent)}
		>
			<div>
				<h1 {...applyCypressData(cypressIds.payments.otp.otpTitle)} className={cx(styles.title, extraStyles.title)}>
					{title}
				</h1>
				<div className={styles.desc} {...privacyParams.description}>
					<p {...applyCypressData(cypressIds.payments.otp.otpDescription)}>{desc || defaultDesc}</p>
				</div>
				<div className={styles.authenticateBox}>
					<div className={cx(styles.subDesc, styles[subDesc?.type as string])} id={subDesc?.id}>
						{subDesc && <IconText icon={subDesc.icon} text={subDesc.text} variant="xsmall--small" />}
					</div>
					<InputAuth
						aria-describedby={subDesc ? subDesc.id : undefined}
						data-testid="input-otp"
						id="payment-otp"
						{...applyCypressData(cypressIds.inputs.paymentOTP)}
						onFill={({ onError, onSuccess, value }) => {
							request(value)
								.then((cb) => {
									errorAttempt.current = 0;

									setSubDesc(getSubDescState(`${LocalT.translate('subDesc.success')}`, 'success'));
									onSuccess(cb);

									otpEventsBackendTrigger({
										action: OTP_ACTION.OTP_SUCCESSFUL,
										backend_service: SNOWPLOW_BACKEND_SERVICES.OTP_SUCCESSFUL,
										label: GA_OTP_LABEL.OTP_SUCCESSFUL,
									});
								})
								.catch((customError) => {
									errorAttempt.current += 1;
									otpEventsBackendTrigger({
										action: `${OTP_ACTION.INCORRECT_PASSWORD_ATTEMPT_NUMBER} ${errorAttempt.current}`,
										backend_service: SNOWPLOW_BACKEND_SERVICES.OTP_INCORRECT_PASSWORD_ATTEMPT_NUMBER,
										label: `${GA_OTP_LABEL.INCORRECT_PASSWORD}_${errorAttempt.current}`,
									});

									onError();

									if (!customError) {
										setSubDesc(getSubDescState(`${LocalT.translate('subDesc.notMach')}`, 'error'));
									} else if (customError.message === 'verification_limit_reached') {
										setSubDesc(getSubDescState(`${LocalT.translate('subDesc.codeSent')}`));
										otpEventsBackendTrigger({
											action: OTP_ACTION.REQUEST_NEW_OTP,
											backend_service: SNOWPLOW_BACKEND_SERVICES.OTP_NEW_REQUEST,
											label: GA_OTP_LABEL.REQUEST_NEW_OTP,
										});
									} else if (customError.message === 'rate_limit_reached') {
										setSubDesc(getSubDescState(`${LocalT.translate('subDesc.rateLimit')}`, 'error'));
									}
								});
						}}
					/>
				</div>
			</div>

			<div {...applyCypressData(cypressIds.payments.button.resendButton)} className={styles.buttonWrapper}>
				<Button
					disabled={!resendButtonAvailable}
					label={resendButtonAvailable ? ctaResend : `${ctaWait} ${readableCountDown}`}
					onClick={() => {
						getCode();
						otpEventsBackendTrigger({
							action: OTP_ACTION.REQUEST_NEW_OTP,
							backend_service: SNOWPLOW_BACKEND_SERVICES.OTP_NEW_REQUEST,
							label: GA_OTP_LABEL.REQUEST_NEW_OTP,
						});
					}}
					variant={resendButtonAvailable ? 'primary' : 'tertiary'}
				/>
			</div>
		</div>
	);
};

export default OTPContent;
