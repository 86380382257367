import { ReactElement } from 'react';

import { Badge } from '@motorway/mw-highway-code';

type NewBadgeProps = {
	wrapperClassName?: string;
};

const NewBadge = ({ wrapperClassName }: NewBadgeProps): ReactElement => (
	<div className={wrapperClassName} data-testid="NewBadgeWrapper">
		<Badge text="New" variant="primary" />
	</div>
);

export default NewBadge;
