import { ReactElement } from 'react';
import { NextRouter } from 'next/router';

import NavDrawer from 'Storybook/local/NavDrawer/NavDrawer';
import { User } from 'Types/user';

import GuestMenu from './GuestMenu/GuestMenu';
import UserMenu from './UserMenu/UserMenu';

import styles from '../SiteNavigation.module.scss';

type MobileNavigationProps = {
	router: NextRouter;
	user: User & { isLoggedOut: boolean };
};

const MobileNavigation = ({ router, user }: MobileNavigationProps): ReactElement => {
	const { isLoggedOut } = user;

	return (
		<div className={styles.menuButton}>
			<NavDrawer router={router}>
				{isLoggedOut ? <GuestMenu router={router} /> : <UserMenu router={router} user={user} />}
			</NavDrawer>
		</div>
	);
};

export default MobileNavigation;
