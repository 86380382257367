/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import cx from 'classnames';
import T from 'i18n-react';
import Link from 'next/link';

import { LogoMotorway } from '@motorway/motorway-storybook-cra';
import {
	Button,
	SocialFacebookIcon,
	SocialInstagramIcon,
	SocialLinkedInIcon,
	SocialXIcon,
} from '@motorway/mw-highway-code';

import cypressIds from 'Components/cypress_ids.json';
import Content from 'Storybook/local/Content/Content';
import { applyCypressData, isTestEnv } from 'Utilities/helpers';
import useIsAuth from 'Utilities/hooks/useIsAuth';
import ROUTES from 'Utilities/routes';

import texts from '../texts.json';

import { footerLinkGaEventHandler } from './SitewideFooter.helpers';

import styles from './SitewideFooter.module.scss';

T.setTexts(texts);

type FooterProps = {
	beforeFixedBottomBar?: boolean | number;
	fixedBottomBarClassName?: string;
};

const Footer = ({ beforeFixedBottomBar = false, fixedBottomBarClassName }: FooterProps): React.ReactElement => {
	// Prevent snapshots breaking every new year
	const year = isTestEnv ? 2023 : new Date().getFullYear();

	const isUserAuthed = useIsAuth();

	return (
		<>
			<footer {...applyCypressData(cypressIds.sitewide.footer)}>
				<Content
					center
					className={styles.navLinksSection}
					element="section"
					wrapper={{ className: styles.navLinksWrapper }}
				>
					<LogoMotorway className={styles.companyLogo} />

					<ul className={styles.socialLinks}>
						<li>
							<a
								href={`${T.translate('footerView.socialLinks.facebookLink')}`}
								rel="noopener noreferrer"
								target="_blank"
								title={`${T.translate('footerView.socialLinks.facebookTitle')}`}
							>
								<SocialFacebookIcon />
							</a>
						</li>
						<li>
							<a
								href={`${T.translate('footerView.socialLinks.twitterLink')}`}
								rel="noopener noreferrer"
								target="_blank"
								title={`${T.translate('footerView.socialLinks.twitterTitle')}`}
							>
								<SocialXIcon />
							</a>
						</li>
						<li>
							<a
								href={`${T.translate('footerView.socialLinks.instagramLink')}`}
								rel="noopener noreferrer"
								target="_blank"
								title={`${T.translate('footerView.socialLinks.instagramTitle')}`}
							>
								<SocialInstagramIcon />
							</a>
						</li>
						<li>
							<a
								href={`${T.translate('footerView.socialLinks.linkedinLink')}`}
								rel="noopener noreferrer"
								target="_blank"
								title={`${T.translate('footerView.socialLinks.linkedinTitle')}`}
							>
								<SocialLinkedInIcon />
							</a>
						</li>
					</ul>

					<div className={styles.sellerSiteButtonRow}>
						<Button
							as="a"
							href={`${T.translate('footerView.sellerSite.buttonLink')}`}
							icon="chevron"
							label={T.translate('footerView.sellerSite.buttonLabel')}
							onClick={() => {
								footerLinkGaEventHandler(T.translate('footerView.sellerSite.buttonLabel'));
							}}
							target="_blank"
							variant="secondary"
						/>
					</div>

					<ul className={styles.ancillaryPages} data-testid="footer-links">
						<li>
							<Link as="/contact-us" href="/static_page">
								<a
									onClick={() => {
										footerLinkGaEventHandler(T.translate('footerView.ancillaryPages.contactUsPageTitle'));
									}}
								>
									{T.translate('footerView.ancillaryPages.contactUsPageTitle')}
								</a>
							</Link>
						</li>
						{isUserAuthed && (
							<li>
								<Link as="/terms" href="/static_page">
									<a
										onClick={() => {
											footerLinkGaEventHandler(T.translate('footerView.ancillaryPages.termsPageTitle'));
										}}
									>
										{T.translate('footerView.ancillaryPages.termsPageTitle')}
									</a>
								</Link>
							</li>
						)}
						<li>
							<Link as="/privacy" href="/static_page">
								<a
									onClick={() => {
										footerLinkGaEventHandler(T.translate('footerView.ancillaryPages.privacyPageTitle'));
									}}
								>
									{T.translate('footerView.ancillaryPages.privacyPageTitle')}
								</a>
							</Link>
						</li>
						<li>
							<Link href={ROUTES.MOTORWAY_PAY_MODULR.href}>
								<a
									onClick={() => {
										footerLinkGaEventHandler(T.translate('footerView.ancillaryPages.motorwayPayModulr'));
									}}
								>
									{T.translate('footerView.ancillaryPages.motorwayPayModulr')}
								</a>
							</Link>
						</li>
						<li>
							<Link as="/cookies" href="/static_page">
								<a
									onClick={() => {
										footerLinkGaEventHandler(T.translate('footerView.ancillaryPages.cookiesPageTitle'));
									}}
								>
									{T.translate('footerView.ancillaryPages.cookiesPageTitle')}
								</a>
							</Link>
						</li>
					</ul>

					<p className={styles.itpMessage}>{T.translate('footerView.itpMessage')}</p>

					<p className={styles.copyright}>{T.translate('footerView.copyrightMessage', { year })}</p>
				</Content>
			</footer>

			{beforeFixedBottomBar && <div className={cx(styles.fixedBottomGap, fixedBottomBarClassName)} />}
		</>
	);
};

export default Footer;
